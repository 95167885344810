import { createSlice } from '@reduxjs/toolkit';
import { createResetState, creatSetState, mapBaseReducers } from '@/utils/reduxUtils';

interface InputInformationInitialState {
  hasChangedValues: boolean
}

const initialState: InputInformationInitialState = {
  hasChangedValues: false
};

const inputInformationSlice = createSlice({
  name: 'inputInformation',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setInputInformationState = creatSetState('inputInformation');
export const resetInputInformationState = createResetState('inputInformation');

export default inputInformationSlice;
