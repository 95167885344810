import { createSlice } from "@reduxjs/toolkit";
import { creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { AdminClass } from "@/services/departmentAndClass";
import { Teacher } from "@/services/teacher";
import { ScheduleType } from "@/containers/preview-export/types";
import { History } from "../services/adjustSchedule";
import {
  ClassScheduleDetailVO,
  IFetchConflictfromArranged,
} from "@/services/courseScheduling/adjustSchedule";

interface IWatingCourseList {
  combinationList: ClassScheduleDetailVO[];
  courseClassMOList: ClassScheduleDetailVO[];
  hebanList: ClassScheduleDetailVO[];
}

export interface AdjustScheduleState {
  // 类型 'AdminClass' === '班级', 'Teacher' === '教师';
  scheduleType: ScheduleType;
  // 当前班级id
  currAdminClassId: string;
  // 当前教师ID
  currAdjustTeacherId: string;
  isEngineReady: boolean;
  // 课表格子第二次点击 x y
  subActive: Record<string, number>;
  // 课表格子第一次点击 x y
  mainActive: Record<string, number>;
  // 是否锁定
  isLocking: boolean;
  // 是否解锁
  isUnlocking: boolean;
  // 课表暂存区数据
  watingCourseList: ClassScheduleDetailVO[];
  pHistoryList: History[];
  rHistoryList: History[];
  // 格子冲突数据
  conflictsInfoArr: IFetchConflictfromArranged[];
  // 暂存区选中id
  sameCellId: string;
  isCanAdd: false;
  currGrade: string; // 班级课表下所选择的年级
  gradeCourseId: string[]; // 教师课表下所用到的年级、课程id
  dropType: "courseInside" | "courseOutside";
}

// TODO: 策略模式
const initialState: AdjustScheduleState = {
  scheduleType: "AdminClass",
  currAdminClassId: "",
  currAdjustTeacherId: "",
  isEngineReady: false,
  subActive: { x: 0, y: 0 },
  mainActive: { x: 0, y: 0 },
  isLocking: false,
  isUnlocking: false,
  watingCourseList: [],
  pHistoryList: [],
  rHistoryList: [],
  conflictsInfoArr: [],
  sameCellId: "",
  isCanAdd: false,
  gradeCourseId: [],
  currGrade: "",
  dropType: "courseInside",
};

const adjustScheduleSlice = createSlice({
  name: "adjustSchedule",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setAdjustScheduleState = creatSetState("adjustSchedule");

export default adjustScheduleSlice;
