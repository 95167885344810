import { setUserState } from "@/store/user";

export const showOpenVipModal = (config?: { type: "export" | "default" }) => {
  // 后端校验弹窗时没有弹窗配置，需要在前端点击的时候再window上面配置，这里自动检测并自动清除

  const c = config || window.showOpenVipModalConfig;

  setUserState({
    vipTipModalConfig: {
      show: true,
      config: {
        ...c,
        type: c?.type || "default",
      },
    },
  });

  Reflect.deleteProperty(window, "showOpenVipModalConfig");
};
