import { createSlice } from "@reduxjs/toolkit";
import { createResetState, creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { ArrangeTeachingStatus } from "@/containers/arrange-teaching/ArrangeTeachingOperators";
import { ScheduleSheetCourse } from "@/services/optionalCourseScheduling/arrangeTeaching";

export interface ArrangeTeachingState {
  activeGrade: string | undefined;
  status: ArrangeTeachingStatus;
  oddCheckedCourses: ScheduleSheetCourse[];
  combineCheckedCourses: ScheduleSheetCourse[];
  stratifiedCheckedCourses: ScheduleSheetCourse[];
  sortedCourseIds: string[];
  needResetData: boolean;
  tipStratifiedStep1Done: boolean; // 已完成分层提示第一步提示
  showBindOddAndDoubleModal: boolean;
}

const initialState: ArrangeTeachingState = {
  activeGrade: undefined,
  status: "default",
  oddCheckedCourses: [],
  combineCheckedCourses: [],
  stratifiedCheckedCourses: [],
  sortedCourseIds: [],
  needResetData: false,
  tipStratifiedStep1Done: false,
  showBindOddAndDoubleModal: false,
};

const OCSArrangeTeachingSlice = createSlice({
  name: "OCSArrangeTeaching",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setOCSArrangeTeachingState = creatSetState("OCSArrangeTeaching");
export const resetOCSArrangeTeachingState = createResetState("OCSArrangeTeaching");

export default OCSArrangeTeachingSlice;
