import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import base from "@/services/base";
import userSlice from "@/store/user";
import adjustScheduleSlice from "./adjustSchedule";
import adjustSubstituteSlice from "./adjustSubstitute";
import arrangeTeachingSlice from "@/store/arrangeTeaching";
import inputInformationSlice from "@/store/inputInformation";
import layoutSlice from "./layout";
import feedbackSlice from "@/store/feedback";
import arrangeInvigilationSlice from "@/store/arrangeInvigilation";
import optionalCourseSchedulingSlice from "@/store/optionalCourseScheduling";
import OCSarrangeTeachingSlice from "@/store/OCSArrangeTeaching";
import OCSAdjustScheduleSlice from "@/store/OCSAdjustSchedule";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    adjustSchedule: adjustScheduleSlice.reducer,
    adjustSubstitute: adjustSubstituteSlice.reducer,
    arrangeTeaching: arrangeTeachingSlice.reducer,
    inputInformation: inputInformationSlice.reducer,
    layout: layoutSlice.reducer,
    feedback: feedbackSlice.reducer,
    arrangeInvigilation: arrangeInvigilationSlice.reducer,
    optionalCourseScheduling: optionalCourseSchedulingSlice.reducer,
    OCSArrangeTeaching: OCSarrangeTeachingSlice.reducer,
    OCSAdjustSchedule: OCSAdjustScheduleSlice.reducer,
    [base.reducerPath]: base.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false, // 由于存在大块数据（400kb），禁用serializable中间件
    });
    return defaultMiddleware.concat(base.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootStateNamespace = keyof RootState;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
