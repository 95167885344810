// 测试环境配置

export default {
  SERVER_URL: 'http://api.local.192basejy.com', // 后端服务域名
  DEPLOY_DIR: '/kuaipk/', // 项目部署的目录
  APP_URL: 'http://www.local.192basejy.com/kuaipk', // 本项目访问地址
  H5_STATIC_SERVER_URL:  'https://tencent.dev-env.basejy.com', // h5页面访问地址
  WX_APPID: 'wx99bc4493f44c706f', // 微信公众号appid
  WX_MP_APP_ID: 'wxef1aa2457078e512', // 微信小程序appid
  HUO_SHAN_MAI_DIAN_APP_ID: 20000733, // 火山引擎埋点appid
  SHOW_MAI_DIAN_LOG: true, // 是否打印埋点日志到浏览器控制台
  ALLOW_MOBILE_LOGIN_PAGE: true, // 是否打开手机登录页面
}
