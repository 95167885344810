import { createSlice } from "@reduxjs/toolkit";
import { creatSetState, mapBaseReducers } from "@/utils/reduxUtils";

export type ScheduleType = "AdminClass" | "Teacher";

export type WatingCourse = "combinationList" | "courseClassMOList" | "hebanList" | "togetherList";

export interface History {
  type: "add" | "remove" | "exchange";
  body: any;
}

export interface Teacher {
  teacherId: string;
  teacherName: string;
}

export interface AdminClass {
  schoolId: string;
  gradeDepartmentId: string;
  gradeId: string;
  gradeName: string;
  id: string;
  name: string;
  abbreviation: string;
}

export interface AdjustScheduleState {
  scheduleType: ScheduleType;
  currAdminClassList: AdminClass[];
  currAdminClassId: string;
  currTeacherList: Teacher[];
  currAdjustTeacherId: string;
  isEngineReady: boolean;
  subActive: Record<string, number>;
  mainActive: Record<string, number>;
  isLocking: boolean;
  isUnlocking: boolean;
  watingCourseList: Record<WatingCourse, any[]>;
  pHistoryList: History[];
  rHistoryList: History[];
  ruleConflicts: Record<string, any> | null;
  hardConflicts: Record<string, any> | null;
  sameCellId: string;
  isCanAdd: false;
  currGrade: string; // 班级课表下所选择的年级
  gradeCourseId: string[]; // 教师课表下所用到的年级、课程id
  dropType: "courseInside" | "courseOutside";
  adjustTableDataMap: any;
}

const initialState: AdjustScheduleState = {
  scheduleType: "AdminClass",
  currAdminClassList: [],
  currAdminClassId: "",
  currTeacherList: [],
  currAdjustTeacherId: "",
  isEngineReady: false,
  subActive: { x: 0, y: 0 },
  mainActive: { x: 0, y: 0 },
  isLocking: false,
  isUnlocking: false,
  watingCourseList: {
    combinationList: [],
    courseClassMOList: [],
    hebanList: [],
    togetherList: [],
  },
  pHistoryList: [],
  rHistoryList: [],
  ruleConflicts: null,
  hardConflicts: null,
  sameCellId: "",
  isCanAdd: false,
  gradeCourseId: [],
  currGrade: "",
  dropType: "courseInside",
  adjustTableDataMap: {},
};

const OCSAdjustScheduleSlice = createSlice({
  name: "OCSAdjustSchedule",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setOCSAdjustScheduleState = creatSetState("OCSAdjustSchedule");

export default OCSAdjustScheduleSlice;
