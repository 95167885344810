import { createSlice } from "@reduxjs/toolkit";
import { createResetState, creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { OptionalCourseSchedulingRecord } from "@/services/optionalCourseScheduling";
import { SchoolInfo } from "@/services/school";

interface OptionalCourseSchedulingState {
  currentScheduling?: OptionalCourseSchedulingRecord;
  showImportModal?: boolean;
  isEngineReady?: boolean;
  showSchedulingList?: boolean;
  inputInformationHasChangedValues?: boolean; // 用户是否改变过录入信息
  inputInformationSaveFunction?: any; // 录入信息保存函数
  hasScheduled?: boolean; // 是否已经排课
  timeTableSchema?: {
    // 作息表
    id?: string;
  };
  hasInit?: boolean; // 是否已经初始化排课信息
  schoolWithName?: SchoolInfo;
}

const initialState: OptionalCourseSchedulingState = {
  currentScheduling: undefined,
  showImportModal: false,
  showSchedulingList: false,
  inputInformationHasChangedValues: false,
  inputInformationSaveFunction: undefined,
  hasScheduled: false,
  hasInit: false,
};

const optionalCourseSchedulingSlice = createSlice({
  name: "optionalCourseScheduling",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setOptionalCourseSchedulingState = creatSetState("optionalCourseScheduling");
export const resetOptionalCourseSchedulingState = createResetState("optionalCourseScheduling");

export default optionalCourseSchedulingSlice;
