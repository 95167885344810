import { createSlice } from '@reduxjs/toolkit';
import { creatSetState, mapBaseReducers } from '@/utils/reduxUtils';

export interface LayoutSlice {
  loading: boolean;
  loadingTips: string;
}

const initialState: LayoutSlice = {
  loading: false, // 排课loading
  loadingTips: 'loading...',
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setLayoutState = creatSetState('layout');

export default layoutSlice;
