import { createSlice } from '@reduxjs/toolkit';
import { creatSetState, mapBaseReducers } from '@/utils/reduxUtils';
import { CourseClassConflictRecord } from "@/services/courseScheduling/adjustSubstitute";

export interface AdjustSubstituteState {
  subActive: Record<string, string>;
  mainActive: Record<string, string>;
  adjustActiveList: Record<string, string>[];
  ruleConflicts: CourseClassConflictRecord[] | undefined;
  hardConflicts: CourseClassConflictRecord[] | undefined;
  sameCellId: string;
  adjustCellIds: string;
  exchangeLabels: string[];
  adjustLabels: string[];
}

// TODO: 策略模式
const initialState: AdjustSubstituteState = {
  subActive: { x: '', y: '' },
  mainActive: { x: '', y: '' },
  adjustActiveList: [],
  ruleConflicts: undefined,
  hardConflicts: undefined,
  sameCellId: '',
  adjustCellIds: '',
  exchangeLabels: [],
  adjustLabels: [],
};

const AdjustSubstituteSlice = createSlice({
  name: 'adjustSubstitute',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setAdjustSubstituteState = creatSetState('adjustSubstitute');

export default AdjustSubstituteSlice;
