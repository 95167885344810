import { createSlice } from "@reduxjs/toolkit";
import { createResetState, creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { ArrangeInvigilationTask } from "@/services/arrangeInvigilation";
import { KUAIPK_DIVIDE_INVIGILATE_CURRENT_TASK } from "@/config/constants";

interface ArrangeInvigilationInitialState {
  currentTask: ArrangeInvigilationTask | undefined;
}

let defaultCurrentTask: ArrangeInvigilationTask | undefined;
try {
  defaultCurrentTask = JSON.parse(
    localStorage.getItem(KUAIPK_DIVIDE_INVIGILATE_CURRENT_TASK) || "null",
  );
} catch (e) {
  console.log(e);
  localStorage.removeItem(KUAIPK_DIVIDE_INVIGILATE_CURRENT_TASK)
}

const initialState: ArrangeInvigilationInitialState = {
  currentTask: defaultCurrentTask || undefined,
};

const arrangeInvigilationSlice = createSlice({
  name: "arrangeInvigilation",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setArrangeInvigilationState = creatSetState("arrangeInvigilation");
export const resetArrangeInvigilationState = createResetState("arrangeInvigilation");

export default arrangeInvigilationSlice;
