import { createSlice } from '@reduxjs/toolkit';
import { createResetState, creatSetState, mapBaseReducers } from '@/utils/reduxUtils';
import { ArrangeTeachingStatus } from '@/containers/arrange-teaching/ArrangeTeachingOperators';
import {
  ScheduleTeachingCourse,
  ScheduleTeachingCourseClass
} from "@/services/courseScheduling/arrangeTeaching";

export interface ArrangeTeachingState {
  activeGrade: string | undefined
  status: ArrangeTeachingStatus
  oddCheckedCourses: ScheduleTeachingCourse[]
  combineCheckedCourseClasses: ScheduleTeachingCourseClass[]
  stratifiedCheckedCourseClasses: ScheduleTeachingCourseClass[]
  sortedCourseIds: string[]
  needResetData: boolean
  tipStratifiedStep1Done: boolean // 已完成分层提示第一步提示
  showBindOddAndDoubleModal: boolean
}

const initialState: ArrangeTeachingState = {
  activeGrade: undefined,
  status: 'default',
  oddCheckedCourses:[],
  combineCheckedCourseClasses: [],
  stratifiedCheckedCourseClasses: [],
  sortedCourseIds: [],
  needResetData: false,
  tipStratifiedStep1Done: false,
  showBindOddAndDoubleModal: false,
};

const arrangeTeachingSlice = createSlice({
  name: 'arrangeTeaching',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setArrangeTeachingState = creatSetState('arrangeTeaching');
export const resetArrangeTeachingState = createResetState('arrangeTeaching');

export default arrangeTeachingSlice;
