import { createSlice } from "@reduxjs/toolkit";
import { creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { User } from "@/types";
import { School, SchoolInfo } from "@/services/school";
import { IUserVipInfo } from "@/services/user";
import { CourseScheduleRecord } from "@/services/courseScheduling/scheduleList";

export interface UserState {
  isLogin: boolean;
  hasInit: boolean;
  userInfo?: User;
  school?: School;
  schoolWithName?: SchoolInfo;
  scheduleScheme?: CourseScheduleRecord;
  customResolveNext: boolean;
  needResolveNext: boolean;
  hasScheduled: boolean;
  showImportDrawer: boolean;
  openLoginModal: boolean;
  accessPerms: string[];
  userVipInfo?: IUserVipInfo;
  vipTipModalConfig?: {
    show: boolean;
    config: { type: "export" | "default" };
  };
  willInTeamwork?: boolean; //
}

const initialState: UserState = {
  isLogin: false,
  hasInit: false,
  userInfo: undefined,
  school: undefined,
  schoolWithName: undefined,
  scheduleScheme: undefined,
  customResolveNext: false, // 页面自行处理下一步逻辑
  needResolveNext: false, // 页面需要处理下一步逻辑
  hasScheduled: true, // 是否已经排课，默认为true不触发自动排
  showImportDrawer: false, // 显示导入课表弹窗
  openLoginModal: false, // 打开登录弹窗
  accessPerms: [], // 用户权限标识符列表
  userVipInfo: undefined,
  vipTipModalConfig: undefined, // 用户开通vip提示弹窗
  willInTeamwork: false, // 即将进入协同模式
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setUserState = creatSetState("user");

export default userSlice;
