import { PayloadAction } from '@reduxjs/toolkit';
import store, { RootState, RootStateNamespace } from '@/store';
import { Reducer } from 'react';

export const mapBaseReducers = (initialState?: any): Record<string, Reducer<any, any>> => {
  return {
    setState: (state: RootState, action: PayloadAction) => {
      const payload = action.payload as unknown as Record<string, any>;

      Object.keys(payload).forEach((key) => {
        // @ts-ignore
        state[key] = payload[key];
      });
    },
    resetState: () => {
      return initialState ? initialState : {}
    }
  };
};

export const creatSetState = (namespace: RootStateNamespace) => {
  return <T>(state: T) => {
    store.dispatch({
      type: `${namespace}/setState`,
      payload: state,
    });
  };
};

export const createResetState = (namespace: RootStateNamespace) => {
  return () => {
    store.dispatch({
      type: `${namespace}/resetState`,
    });
  };
}
